.navigation-flex-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-left: 10%;
    margin-right: 10%;
}

.navbar {
    /* background-color: #333; */
    color: white;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
 
.navbar-logo {
    min-width: 30px;
    min-height: 30px;
    height: auto;
    padding-left: 0;
    padding-top: 10px;
    padding-bottom: 10px;
}
  
.navbar ul {
    text-decoration: none;
    list-style: none;
    padding: 10px;
    margin: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.mylink {
text-decoration: none;
}

.navbar ul li {
/* text-decoration: none; */
color: #333; 
align-items: center;
padding: 10px;
}


.navbar ul svg {
margin-right: 5px;
}

.mylink:hover {
color: #5e6fa0;
}

.mylink svg {
margin-right: 5px; 
}

.reralabs-logo {
    padding-top: 5%;
}


@media (max-width: 768px) {
    .navigation-flex-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .navbar {
        display: none; /* Hide nav links on small screens */
        flex-direction: column;
      }

    .navbar ul {
        text-decoration: none;
        list-style: none;
        padding: 10px;
        margin: 0;
        display: block;
        justify-content: space-around;
        align-items: center;
    }


  .navbar-item {
    width: calc(50% - 20px);
    flex-grow: 1;
    box-sizing: border-box;
    /* border: 1px solid #ccc;
    max-width: 50%; */
    }

    .align-right {
        justify-content: flex-end;
    }

    .flex-full-width ul {
        list-style-type: none;
    }

  .navbar ul.active {
    display: flex; /* Show nav links when active */
  }

  .navbar-toggle {
    display: flex;
    flex-basis: 50%;
    /* flex-direction: column; */
    /* flex: 2; */
    height: auto;
    /* justify-content: space-between;  */
    align-items: center; 
    /* border: 1px solid #ccc; */
  }

  .navbar-mobile {
    /* flex: 100%; */
    display: block;
    position: fixed;
    top: 60px;
    /* left: 0; */
    right: 0;
  }


  .navbar-burger-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    min-width: 40px;
    height: auto; 
    cursor: pointer;
    /* transition: transform 0.3s ease; */
    padding : 20px;
    /* background-color: rgb(85, 82, 82); */
    }   

    .navbar-mobile.active{
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition: 0.5s ease;
        z-index: 1000;
    }

    .navbar-mobile.inactive{
        display: none;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-20px);
        transition: 0.5s ease;
        z-index: -1;
    }

    .navbar-mobile ul li {
        padding: 5px 10px;
    }

    .flex-full-width {
        flex: 0 0 100%; 
    }

    .flex-empty-50{
        flex: 0 0 50%;
        min-height: 1px;
        max-width: 50%;
    }

}



/* .float-left{
    float: left;
} */

/* .flex-50 {
    max-width: 50%;
}



.flex-full-width {
    flex: 0 0 100%; 
} */

.themeDark {
    color: rgb(204, 216, 220);
  }

