.flex-container-rental {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next line */
  }
  
.item-rental {
    flex: 1 1 auto; /* Each item gets equal flex-grow factor */
    height: 100px; /* Adjust height as needed */
    background-color: rgb(113, 106, 106); /* Adjust color as needed */
    border: 1px solid #000; /* Just for demo */
    box-sizing: border-box; /* Include padding and border in width calculation */
  }