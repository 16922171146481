/* .container-form {
    display: flex;
}

.InvestmentForm {
    display: flex;
} */


.flex-dashboard {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    flex: 1 1 auto;
    align-items: stretch;
    /* justify-content: center; */
    /* position: relative; */
    width: 100%;
    height: 100%;
    /* text-align: center; */
    overflow-x: auto;
    flex-basis: 100%;
    box-sizing: border-box;

}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
    .flex-dashboard {
      display: block;
      text-align: center; /* Center-align block-level elements */
    }

    .InvestmentForm{
        flex: 1;
        margin-top: 10%;
        display: flex;
        flex-direction: column;
    }
    
    .form-group label {
        flex: 0 0 120px;
        margin-right: 10px; 
      }
      
    .form-group input {
    flex: 1; 
    }
  }

.flex-item-dashboard {
    display: flex;
    flex: 0 0 25%;
    /* height: 100px;  */
    background-color: rgb(113, 106, 106); /* Adjust color as needed */
    border: 1px solid #000; /* Just for demo */
    box-sizing: border-box; /* Include padding and border in width calculation */
  }

.speedometer{
    flex: 1;
    margin: auto;
}

.InvestmentForm{
    flex: 1;
    margin-top: 10%;
    /* display: flex; */
    /* flex-direction: column; */
}

/* .form-group label {
    flex: 0 0 120px;
    margin-right: 10px; 
  }
  
.form-group input {
flex: 1; 
} */


.readOnly-field{
    background-color: rgb(156, 148, 148);
}

.echarts-for-react > div:not([class]):not([id]) {
    margin: auto;
}

.flex-investment-return{
    display: flex;
    flex-wrap: wrap;
    flex: 2;
    align-items: stretch;
    /* justify-content: center; */
    /* position: relative; */
    width: 100%;
    height: 100%;
}

.user-initial-form{
    margin-left: 1px;
}

.user-initial-amount{
    margin-left: 20px;
}

.table {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: auto; /* Adjust width as needed */
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden; /* Ensure rounded corners */
}

.table-row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ccc;
}

.table-cell {
  flex: 1;
  padding: 8px;
}

.header {
    border-top: 1px solid #ccc;
}

.align-right {
    text-align: right;
}

.submit-button{
    align-self: center;
}
