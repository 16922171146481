.footer-flex-container {
  margin-left: 10%;
  margin-right: 10%;
  /* border-width: 100px; */

}

.site-footer {
  /* background-color:#26272b; */
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: rgb(203, 196, 222);
}

.container {
  display: flex;
  justify-content: space-between;
}

.container-social {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgb(203, 196, 222);
}

.flex-row-social {
  display: flex;
  flex: 1 1 50%;
  justify-content: space-between;
  width: 100%;
  /* margin-top: 5px; */
}

.site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5
}

.site-footer hr.small {
  margin: 20px 0
}

.site-footer h6 {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px
}

.site-footer a {
  color: #737373;
}

.site-footer a:hover {
  color: #3366cc;
  text-decoration: none;
}

.footer-links {
  padding-left: 0;
  list-style: none
}

.footer-links li {
  display: block
}

.footer-links a {
  color: #737373
}

.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: #3366cc;
  text-decoration: none;
}

.footer-links.inline li {
  display: inline-block
}

.site-footer .social-icons {
  text-align: right
}

.flex-row {
  display: flex;
  justify-content: flex-start;
}

.flex-3 {
  max-width: 60%;
  /* margin: 0% 5%; */
  width: auto;
}


.flex-2 {
  /* max-width: calc(100% - 20%); */
  margin: 0% 5%;
  width: auto;
}

.footer-item-align-left {
  margin-left: 0;
  margin-right: 5%;
}

.footer-item-align-center {
  margin-right: 5%;
}

.footer-item-align-right {
  margin-right: 0;

}

.site-footer .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #33353d
}

.copyright-text {
  margin-top: 5%;
}

@media (max-width:991px) {
  .site-footer [class^=col-] {
    margin-bottom: 30px
  }
}

@media (max-width:767px) {
  .footer-flex-container {
    margin-left: 1%;
    margin-right: 1%;
    /* border-width: 100px; */

  }

  .container-social {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgb(203, 196, 222);
  }

  .site-footer {
    padding-bottom: 0
  }

  /* .site-footer .copyright-text{
    text-align: left;
  } */

  
  .site-footer .social-icons {
    text-align: center
  }
}

.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.social-icons li {
  display: inline-block;
  /* margin-bottom: 4px */
}

.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px
}

.social-icons a {
  background-color: #eceeef;
  color: #818a91;
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 100%;
  -webkit-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all .2s linear
}

.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: #fff;
  background-color: #29aafe
}

.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px
}

.social-icons a.facebook:hover {
  background-color: #3b5998
}

.social-icons a.linkedin:hover {
  background-color: #0995c8
}

.social-icons a.instagram:hover {
  background-color: #b6006d
}

.social-icons a.youtube:hover {
  background-color: #ec0606
}

@media (max-width:767px) {
  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600
  }
}