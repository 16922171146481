.flex-image-carousel {
    display: flex;
    flex-wrap: wrap;
    flex: 4;
    align-items: stretch;
    /* justify-content: center; */
    /* position: relative; */
    width: 100%;
    height: 100%;
    /* text-align: center; */
    overflow-x: auto;
    flex-basis: 100%;
    box-sizing: border-box;
    
  }

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .flex-image-carousel {
    flex-direction: column;
    /* display: block; */
    text-align: center; /* Center-align block-level elements */
  }

  .part-1,
  .part-4{
    visibility: hidden;
  }
}
  
  .carousel-image {
    max-width: 100%; /* Ensure the image does not exceed the width of its container */
    max-height: 100%; /* Ensure the image does not exceed the height of its container */
    width: auto; /* Allow the image to scale horizontally */
    height: 100%; /* Allow the image to scale vertically */
  }
  
  .nav-btn {
    position: absolute;
    /* top: 50%; */
    /* transform: translateY(-50%); */
    background-color: rgb(113, 106, 106);
    color: rgb(255, 255, 255);
    font-size: 24px;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
  }
  
  .prev-btn {
    position: absolute;
    left: 50px;
  }
  
  .next-btn {
    position: absolute;
    right: 50px;
  }

.part-1,
.part-4{
  display: flex;
  flex-grow: 0;
  flex-basis: 10%;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
}
.part-2{
  /* flex-direction: column; */
  /* height: auto; */
  flex: 1;
  padding: 10px 0px;
  /* flex-basis: 100%; */
  flex-grow: 1;
  border: 1px solid #000;

}
.part-3 {
  /* flex-direction: column; */
  height: 100%;
  flex: 1;
  padding: 10px 0px 10px 10px;
  /* flex-basis: 100%; */
  flex-grow: 1;
  border: 1px solid #0000005e;
}

.flex-dashboard-parent { 
  display: flex;
  flex-wrap: wrap;
  /* flex: 4; */
  align-items: stretch;
  /* justify-content: center; */
  /* position: relative; */
  width: 100%;
  height: 100%;
  /* text-align: center; */
  overflow-x: auto;
  flex-basis: 100%;
  box-sizing: border-box;
}

.item-dashboard{
  display: flex;
  flex: 1;
}

/* .cta {
  font-weight: bold;
  font-size: medium;
  
  padding-left: 1%;

} */

/* .part-2 img {
  max-width: 100%;
  height: auto;
} */

/* .part-3 {
  background-color: #f0f0f0;
  padding: 20px;
} */

.chartHeader {
  text-align: center;
}

.echarts-for-react\  {
  width: 100%; 
  height: auto; 
}

.echarts-for-react svg {
  /* Set the width and height of the SVG */
  width: 100%; /* Make the SVG fill the width of its container */
  height: 100%; /* Make the SVG fill the height of its container */
}

.pageContainer {
  height: 100%;

}